// @function flip($value_ltr, $value_rtl) {
//     @if $rtl { @return $value_rtl; }
//     @else { @return $value_ltr; }
// }
  
//   $padding-left:    padding-left;
//   $padding-right:   padding-right;
//   $margin-left:     margin-left;
//   $margin-right:    margin-right;
//   $border-right:    border-right;
//   $left:            left;
//   $right:           right;
  
//   @if $rtl {
//     $padding-left:  padding-right;
//     $padding-right: padding-left;
//     $margin-left:   margin-right;
//     $margin-right:  margin-left;
//     $border-right:  border-left;
//     $left:          right;
//     $right:         left;
//     }

@mixin make-col($size, $columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}