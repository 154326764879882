/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import './styles/_helper.scss';
 

body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.container{
  max-width: 1170px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
:root{
  --main-menu-bg: #303643;
  --sub-menu-bg: #595F6A;
  --sub-menu-bg-active:#DBDBE0;
  --main-font-color:#fff;
  --custom-scrollbar-bg:#eeeeee;
  --custom-scrollbar-thumb-bg:#abaaaa;
  --second-font-color:#555764;
  --grid-header-bg:#DBDBE0;
  --primary:#EE2D35;
  --error: #d51923;


}