@import './mixins';

.d-flex{display: flex;}
.list-unStyle{list-style: none;}
.no-padding{padding: 0;}

.form-group {
    margin-bottom: 1rem;
}
.astric{color: var(--error);}
.toolbar{
    background: var(--grid-header-bg);
    margin-bottom: 10px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    overflow-x: hidden;
}
.row{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
}
.component-container{
    padding: 20px 40px;
}
 
.d-block{display: block}
@for $i from 1 through 12 {
    .col-#{$i} {
        @include make-col($i,12);
    }
    .col-offset-#{$i}{
        @include make-col-offset($i,12);

    }
  }
  .fw-500{
      font-weight: 500;
  }
